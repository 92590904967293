import { Injectable } from "@angular/core";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  fieldUserGroup: any[] = [];

  constructor(private router: Router) {}

  showWarning(
    titleWarning: any,
    textWarning: any,
    iconWarning: any,
    timerWarning: any,
    timerProgressBarWarning: boolean,
    isRedirect: boolean,
    navigate: String
  ) {
    Swal.fire({
      title: titleWarning,
      text: textWarning,
      icon: iconWarning,
      timer: timerWarning,
      timerProgressBar: timerProgressBarWarning,
    });
    if (isRedirect) {
      this.router.navigate([navigate]);
    }
  }

  getDate() {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-").toString();
  }

  getTime() {
    var d = new Date(),
      hour = "" + d.getHours(),
      minute = "" + d.getMinutes();

    var hour = hour.toString();
    if (parseInt(hour) < 10) {
      hour = "0" + parseInt(hour);
    }

    var minute = minute.toString();
    if (parseInt(minute) < 10) {
      minute = "0" + parseInt(minute);
    }

    return hour + ":" + minute;
    // return [hour, minute].join(':').toString();
  }

  convertDate(date: any): string {
    if (date === null) return "2999-12-31";
    var fullDate = new Date(date);
    console.log("full date nya adalah: ", fullDate);
    var bulanSwitch;
    var tanggal;
    var mydate = new Date(date);
    console.log(mydate.toDateString());

    var tahun = fullDate.getFullYear();
    bulanSwitch = fullDate.getMonth() + 1;
    if (bulanSwitch < 10) {
      bulanSwitch = "0" + bulanSwitch;
    }
    tanggal = fullDate.getDate();

    if (tanggal < 10) {
      tanggal = "0" + tanggal;
    }

    var result = tahun + "-" + bulanSwitch + "-" + tanggal;
    return result;
  }
}
