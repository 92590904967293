import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
  selector: "ngx-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  isLoading: boolean = false;
  fieldCabangArray: any[] = [];
  bidangUsahaArray: any[] = [];
  jenisPerusahaanArray: any[] = [];
  registerForm: FormGroup;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.formValidation();
    this.onGetMasterPrincipal();
  }

  login(value) {}

  formValidation(): void {
    this.registerForm = new FormGroup({
      username: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
      ]),
      password: new FormControl(null, [Validators.required]),
      cabangProduksi: new FormControl("", [Validators.required]),
      namaPerusahaan: new FormControl(null, [Validators.required]),
      alamatPerusahaan: new FormControl(null, [Validators.required]),
      bidangUsaha: new FormControl("", [Validators.required]),
      jenisPerusahaan: new FormControl("", [Validators.required]),
      recaptcha: new FormControl("", [Validators.required]),
      kodePos: new FormControl(null, [
        Validators.required,
        Validators.maxLength(5),
        Validators.pattern("^[0-9]+$"),
      ]),
      emailPerusahaan: new FormControl(null, [Validators.required]),
      noTelepon: new FormControl(null, [Validators.required]),
      noFax: new FormControl(null, [Validators.required]),
      npwp: new FormControl(null, [Validators.required]),
      tanggalBerdiri: new FormControl(null, [Validators.required]),
      tempatBerdiri: new FormControl(null, [Validators.required]),
      siup: new FormControl(null, [Validators.required]),
      siujk: new FormControl(null, [Validators.required]),
      jabatan: new FormControl(null, [Validators.required]),
      namaKontak: new FormControl(null, [Validators.required]),
      agreement: new FormControl(null, [Validators.required]),
    });
  }

  form(property: any): AbstractControl {
    return this.registerForm.get(property);
  }

  onGetMasterPrincipal() {}
  showWarning(
    titleWarning: any,
    textWarning: any,
    iconWarning: any,
    timerWarning: any,
    timerProgressBarWarning: boolean,
    isRedirect: boolean
  ) {
    this.isLoading = false;
    console.log(" notifinya ada disini yahh");
    Swal.fire({
      title: titleWarning,
      text: textWarning,
      icon: iconWarning,
      timer: timerWarning,
      timerProgressBar: timerProgressBarWarning,
    });
    if (isRedirect) {
      this.router.navigate(["/pages/akseptasi"]);
    }
  }
}
