<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo"><img src="../../../assets/images/GAMA-02.png"><span></span></a>
    </div>
    <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
        <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select> -->
</div>

<div class="header-container" style="color: black !important">
    <nb-actions size="small" style="color: black !important">
        <nb-action class="user-action" *nbIsGranted="['view', 'user']" style="color: black !important">
            <nb-user [nbContextMenu]="userMenu" style="color: black !important" [onlyPicture]="userPictureOnly" [name]="user?.name">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>