import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { HttpParams } from "@angular/common/http";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { AuthLoginService } from "../../AuthLoginService";
import { LoginRequest } from "../../@core/data/data-wco/login";
import { LoginService } from "../../@core/mock/mock-wco/login.service";
import { SecurityService } from "../../security/security.service";

@Component({
  selector: "ngx-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  loginForm: FormGroup;
  fieldLogin: LoginRequest;
  token: any;
  x: any;
  valueEmail!: string;
  forgetPassword: any;
  isLoading: boolean = false;
  showPassword = false;
  passwordFirst: String;
  passwordSecond: String;
  isErrorValidation: Boolean = false;
  username: String = "";
  idIUser: String = "";
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private securityService: SecurityService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params && params.id) {
        if (this.securityService.getCookies("tempToken") !== null) {
          this.username = params.id;
          this.formValidation();
        } else {
          this.router.navigate(["/auth"]);
        }
      } else {
        this.router.navigate(["/auth"]);
      }
    });
  }

  form(property: any): AbstractControl {
    return this.loginForm.get(property);
  }

  passwordMatch() {
    if (this.passwordSecond !== this.passwordFirst) {
      this.isErrorValidation = true;
    } else {
      this.isErrorValidation = false;
    }
  }

  formValidation(): void {
    this.loginForm = new FormGroup({
      username: new FormControl(this.username),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern("(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{8,20})$"),
      ]),
      konfirmasiPassword: new FormControl(null, [Validators.required]),
    });
  }

  resetPassword(value: LoginRequest) {
    this.isLoading = true;
    var idUser = localStorage.getItem("idUser").replace(/["]/g, "");

    var payloadBody = {
      username: idUser,
      oldPassword: "",
      newPassword: value.password,
    };
    this.loginService.changePassword(payloadBody).subscribe(
      (data: any) => {
        Swal.fire({
          title: "Password berhasil diganti. Silahkan Login ulang",
          icon: "success",
          timer: 5000,
          timerProgressBar: true,
        });
        this.securityService.logout();
        this.router.navigate(["/auth"]);
      },
      (error) => {
        console.log(error);
        Swal.fire({
          title: "Failed",
          text: error.error.errorMessage,
          icon: "warning",
          timer: 5000,
          timerProgressBar: true,
        });
        this.isLoading = false;
      }
    );
  }

  getInputType() {
    if (this.showPassword) {
      return "text";
    }
    return "password";
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
