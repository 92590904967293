import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SecurityService } from "../../security/security.service";

@Component({
  selector: "ngx-logout",
  template: "",
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private securityService: SecurityService
  ) {}

  ngOnInit(): void {
    this.securityService.logout();
  }
}
