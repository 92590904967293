import { Component, OnInit } from "@angular/core";
import { Route, Router } from "@angular/router";

@Component({
  selector: "ngx-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  currentYear = new Date().getFullYear();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToLogin() {
    this.router.navigate(["/auth"]);
  }
}
