import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  ChangePasswordRequest,
  ForgetPasswordRequest,
  ForgetPasswordResponse,
  LoginData,
  LoginRequest,
  LoginResponse,
  logoutResponse,
} from "../../data/data-wco/login";
import { SecurityService } from "../../../security/security.service";

@Injectable({
  providedIn: "root",
})
export class LoginService extends LoginData {
  constructor(
    private httpClient: HttpClient,
    private securityService: SecurityService
  ) {
    super();
  }

  postLoginService(formLogin: LoginRequest): Observable<LoginResponse> {
    const body = new HttpParams()
      .set("username", formLogin.username)
      .set("password", formLogin.password)
      .set("grant_type", "password")
      .toString();

    const loginValue = "gama-be" + ":" + "x2fzh4c8-8ej3-52bf-85g3-554bfr65t871";

    const headers = {
      Authorization: "Basic " + btoa(loginValue),
      "Content-type": "application/x-www-form-urlencoded",
    };

    return new Observable((observer: Observer<LoginResponse>) => {
      this.httpClient.post("/api/oauth/token", body, { headers }).subscribe(
        (response: any) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  getLoginData(login: LoginRequest): Observable<LoginResponse> {
    const token = localStorage.getItem("token");
    let headersDict = {
      Authorization: "Bearer " + JSON.parse(token!).access_token,
    };
    let requestOptions = {
      headers: new HttpHeaders(headersDict),
    };
    return new Observable((observer: Observer<any>) => {
      this.httpClient
        .get("/api/suretyonline/api/v1/login" + login, requestOptions)
        .subscribe(
          (response: any) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error.message);
          }
        );
    });
  }

  changePassword(payloadRequest: any): Observable<any> {
    let headersDict = {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };

    console.log("Bearer " + JSON.parse(localStorage.getItem("token")));

    let requestOptions = {
      headers: new HttpHeaders(headersDict),
    };

    return new Observable((observer) => {
      this.httpClient
        .put("/api/user/password", payloadRequest, requestOptions)
        .subscribe(
          (response: any) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  resetPassword(idUser: any) {
    let headersDict = {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
    };

    let requestOptions = {
      headers: new HttpHeaders(headersDict),
    };

    return new Observable((observer: Observer<any>) => {
      this.httpClient
        .put("/api/user/password/reset/" + idUser, requestOptions)
        .subscribe(
          (response: any) => {
            observer.next(response);
          },
          (error) => {
            observer.next(error);
          }
        );
    });
  }

  changePasswordUpdate(
    changePasswordRequest: ChangePasswordRequest
  ): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.httpClient
        .post("/api/suretyonline/api/v1/changepassword", changePasswordRequest)
        .subscribe(
          (response: any) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  loginToServer(login: LoginRequest) {
    return new Observable((observer: Observer<LoginResponse>) => {
      this.httpClient.post("/api/suretyonline/api/v1/login", login).subscribe(
        (response: LoginResponse) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  logoutFromServer(token: String): Observable<logoutResponse> {
    const headers = {
      Authorization: "Bearer " + token,
    };
    return new Observable((observer: Observer<any>) => {
      this.httpClient
        .get("/api/suretyonline/api/v1/logout", { headers })
        .subscribe(
          (response: any) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error.message);
          }
        );
    });
  }

  forgetPassword(
    forgetPasswordRequest: ForgetPasswordRequest
  ): Observable<ForgetPasswordResponse> {
    return new Observable((observer: Observer<any>) => {
      this.httpClient
        .post("/api/suretyonline/api/v1/forgetpassword", forgetPasswordRequest)
        .subscribe(
          (response: any) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error.message);
          }
        );
    });
  }
}
