import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { AuthLoginService } from "../../AuthLoginService";

import { LoginService } from "../../@core/mock/mock-wco/login.service";

@Component({
  selector: "ngx-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"],
})
export class ForgetPasswordComponent implements OnInit {
  loginForm: FormGroup;
  token: any;
  x: any;
  valueEmail!: string;
  forgetPassword: any;
  isLoading: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private authLoginService: AuthLoginService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.formValidation();
    if (this.router.url === "/") {
      this.router.navigate(["/auth"]);
    } else {
      if (localStorage.getItem("token") != null) {
        this.router.navigate(["/pages"]);
      }
    }
  }

  form(property: any): AbstractControl {
    return this.loginForm.get(property);
  }

  formValidation(): void {
    this.loginForm = new FormGroup({
      username: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
      ]),
    });
  }

  login(value) {
    this.isLoading = true;
  }

  setToken(data: any) {
    var menu = [];
    for (let menuData of data.menu) {
      if (menuData.id_user === data.id_user) {
        menu.push(menuData);
      }
    }
    localStorage.setItem("menu", JSON.stringify(menu));
    localStorage.setItem("role", JSON.stringify(data.role));
    localStorage.setItem("fullname", JSON.stringify(data.fullname));
    localStorage.setItem("token", JSON.stringify(data.token));
    localStorage.setItem("groupID", JSON.stringify(data.id_group));
    localStorage.setItem("idUser", JSON.stringify(data.id_user));
    this.authLoginService.login();
  }

  checkConfirmationToken(confirmationToken: String) {
    console.log(confirmationToken, "confirmation tokennya");
    if (confirmationToken === null) {
      return false;
    }
    return true;
  }
}
