import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LoginResponse } from "../@core/data/data-wco/login";
import { AuthLoginService } from "../AuthLoginService";

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  constructor(
    private authLoginService: AuthLoginService,
    private router: Router
  ) {}

  logout() {
    localStorage.clear();
    this.router.navigate(["/auth/"]);
  }

  login(cookies: any) {
    this.authLoginService.login();
    this.setCookies(cookies);
    this.router.navigate(["/pages/"]);
  }

  getCookies(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  setCookies(arrayCookies: any): void {
    for (var cookie of arrayCookies) {
      console.log("cookeinya adalah:", cookie);
      localStorage.setItem(cookie.key, JSON.stringify(cookie.value));
    }
  }

  getCookiesArray(arrayKey: any): any {
    var cookiesArray = [];
    for (var cookie of arrayKey) {
      if (localStorage.getItem(cookie) !== null) {
        cookiesArray.push(localStorage.getItem(cookie));
      }
    }
    return cookiesArray;
  }

  isAdmin(): boolean {
    var role = JSON.parse(localStorage.getItem("role"));
    return role[0].roleName.toLowerCase() === "admin" ? true : false;
  }

  isRoleMatch(value: string): boolean {
    var role = JSON.parse(localStorage.getItem("role"));
    return role[0].roleName.toLowerCase() === value ? true : false;
  }
}
