import { Observable } from "rxjs";

export interface requestLogin {
    username: string;
    password: string;
}

export interface responseLogin {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: string;
    scope: string;
    data: {
        clientId: string;
        transactionDateRequest: string;
        transactionDateResponse: string;
        transactionIdRequest: string;
        role: string;
    };
    jti: string;
}

export interface responseTransaction {
    createdBy: string,
    createdDate: string,
    modifiedBy: string,
    modifiedDate: string,
    id: string,
    clientId: string,
    transactionId: string,
    nik: string,
    accesToken: string,
    expiresIn: Number,
    noVa: String
}

export abstract class AuthData {
    abstract loginData(fieldLogin: requestLogin): Observable<responseLogin>;
    abstract validasiTokenData(accessToken: string): Observable<boolean>;
    abstract getRequestByTransactionId(transactionID: string): Observable<responseTransaction>;
}