<div class="wrapper">
    <div class="checked">
        <div class="header">
            <img src="../../../assets/images/Gama.jpg" alt="logo Gama">
        </div>
        <div class="body">
            <p class="title-body">Pendaftaran User Principal</p>
            <form [formGroup]="registerForm" (ngSubmit)="login(registerForm.value)">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="cabangProduksi">Cabang Produksi</label>
                    <div class="col-sm-8">
                        <select formControlName="cabangProduksi" class="col-xs-12 full" style="width: 100%;">
                            <option class="form-control" *ngFor="let value of fieldCabangArray" [value]="value.id_cabang.toString()">{{value.nama_cabang.toString()}}</option>
                        </select>
                        <ng-container *ngIf="form('cabangProduksi').invalid && (form('cabangProduksi').dirty || form('cabangProduksi').touched)">
                            <p class="caption status-danger" *ngIf="form('cabangProduksi').errors?.required">
                                Cabang harus diisi!
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="namaPerusahaan">Nama Perusahaan</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="namaPerusahaan" aria-describedby="namaPerusahaan" formControlName="namaPerusahaan">
                        <ng-container *ngIf="form('namaPerusahaan').invalid && (form('namaPerusahaan').dirty || form('namaPerusahaan').touched)">
                            <p class="caption status-danger" *ngIf="form('namaPerusahaan').errors?.required">
                                Nama Perusahaan harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="jenisPerusahaan">Jenis Perusahaan</label>
                    <div class="col-sm-8">
                        <select formControlName="jenisPerusahaan" class="col-xs-12 full" style="width: 100%;">
                                <option class="form-control" *ngFor="let value of jenisPerusahaanArray" [value]="value.lookup_key">{{value.label}}</option>
                            </select>
                        <ng-container *ngIf="form('jenisPerusahaan').invalid && (form('jenisPerusahaan').dirty || form('jenisPerusahaan').touched)">
                            <p class="caption status-danger" *ngIf="form('jenisPerusahaan').errors?.required">
                                Jenis Perusahaan harus diisi!
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="alamatPerusahaan">Alamat Perusahaan</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="alamatPerusahaan" aria-describedby="alamatPerusahaan" formControlName="alamatPerusahaan">
                        <ng-container *ngIf="form('alamatPerusahaan').invalid && (form('alamatPerusahaan').dirty || form('alamatPerusahaan').touched)">
                            <p class="caption status-danger" *ngIf="form('alamatPerusahaan').errors?.required">
                                Alamat Perusahaan harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="kodePos">Kode Pos</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="kodePos" aria-describedby="kodePos" formControlName="kodePos">
                        <ng-container *ngIf="form('kodePos').invalid && (form('kodePos').dirty || form('kodePos').touched)">
                            <p class="caption status-danger " *ngIf="form('kodePos').errors?.required">
                                Kode Pos harus diisi
                            </p>
                            <p class="caption status-danger " *ngIf="form('kodePos').errors?.pattern">
                                kodePos Harus diisi angka
                            </p>
                            <p class="caption status-danger " *ngIf="form('kodePos').errors?.maxlength">
                                Kode pos maksimal 5 karakter!
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="emailPerusahaan">Email Perusahaan</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="emailPerusahaan" aria-describedby="emailPerusahaan" formControlName="emailPerusahaan">
                        <ng-container *ngIf="form('emailPerusahaan').invalid && (form('emailPerusahaan').dirty || form('emailPerusahaan').touched)">
                            <p class="caption status-danger" *ngIf="form('emailPerusahaan').errors?.required">
                                Email Perusahaan harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="noTelepon">No Telepon</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="noTelepon" aria-describedby="noTelepon" formControlName="noTelepon">
                        <ng-container *ngIf="form('noTelepon').invalid && (form('noTelepon').dirty || form('noTelepon').touched)">
                            <p class="caption status-danger" *ngIf="form('noTelepon').errors?.required">
                                Nomor Telepon harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="noFax">No Fax</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="noFax" aria-describedby="noFax" formControlName="noFax">
                        <ng-container *ngIf="form('noFax').invalid && (form('noFax').dirty || form('noFax').touched)">
                            <p class="caption status-danger" *ngIf="form('noFax').errors?.required">
                                Nomor Fax harus diisi
                            </p>
                        </ng-container>

                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="npwp">NPWP</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="npwp" aria-describedby="npwp" formControlName="npwp">
                        <ng-container *ngIf="form('npwp').invalid && (form('npwp').dirty || form('npwp').touched)">
                            <p class="caption status-danger" *ngIf="form('npwp').errors?.required">
                                NPWP harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="tanggalBerdiri">Tanggal Berdiri</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="tanggalBerdiri" aria-describedby="tanggalBerdiri" formControlName="tanggalBerdiri">
                        <ng-container *ngIf="form('tanggalBerdiri').invalid && (form('tanggalBerdiri').dirty || form('tanggalBerdiri').touched)">
                            <p class="caption status-danger" *ngIf="form('tanggalBerdiri').errors?.required">
                                Tanggal berdiri harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="tempatBerdiri">Tempat Berdiri</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="tempatBerdiri">
                        <ng-container *ngIf="form('tempatBerdiri').invalid && (form('tempatBerdiri').dirty || form('tempatBerdiri').touched)">
                            <p class="caption status-danger" *ngIf="form('tempatBerdiri').errors?.required">
                                Tempat berdiri harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="bidangUsaha">Bidang Usaha</label>
                    <div class="col-sm-8">
                        <select formControlName="bidangUsaha" class="col-xs-12 full" style="width: 100%;">
                            <option class="form-control" *ngFor="let value of bidangUsahaArray" [value]="value.lookup_key">{{value.label}}</option>
                        </select>
                        <ng-container *ngIf="form('bidangUsaha').invalid && (form('bidangUsaha').dirty || form('bidangUsaha').touched)">
                            <p class="caption status-danger" *ngIf="form('bidangUsaha').errors?.required">
                                Bidang Usaha harus diisi!
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="siup">SIUP</label>
                    <div class="col-sm-8">
                        <input type="siup" class="form-control form-control-sm" id="siup" aria-describedby="siup" formControlName="siup">
                        <ng-container *ngIf="form('siup').invalid && (form('siup').dirty || form('siup').touched)">
                            <p class="caption status-danger" *ngIf="form('siup').errors?.required">
                                SIUP harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="siujk">SIUJK</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="siujk" aria-describedby="siujk" formControlName="siujk">
                        <ng-container *ngIf="form('siujk').invalid && (form('siujk').dirty || form('siujk').touched)">
                            <p class="caption status-danger" *ngIf="form('siujk').errors?.required">
                                SIUJK harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="namaKontak">Nama Kontak</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="namaKontak" aria-describedby="namaKontak" formControlName="namaKontak">
                        <ng-container *ngIf="form('namaKontak').invalid && (form('namaKontak').dirty || form('namaKontak').touched)">
                            <p class="caption status-danger" *ngIf="form('namaKontak').errors?.required">
                                Nama Kontak harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="jabatan">Jabatan</label>
                    <div class="col-sm-8">
                        <input type="email" class="form-control form-control-sm" id="jabatan" aria-describedby="emailHelp" formControlName="jabatan">
                        <ng-container *ngIf="form('jabatan').invalid && (form('jabatan').dirty || form('jabatan').touched)">
                            <p class="caption status-danger" *ngIf="form('jabatan').errors?.required">
                                Jabatan harus diisi
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="form-group row ml-3">
                    <div class="offset-xs-2 col-xs-2">
                        <input class="form-check-input" formControlName="agreement" type="checkbox" id="agreement">
                    </div>
                    <div class="col-xs-8">
                        <label for="agreement">Data yang diinput adalah data yang benar, sesuai dan masih berlaku</label>
                        <ng-container *ngIf="form('agreement').invalid && (form('agreement').dirty || form('agreement').touched)">
                            <p class="caption status-danger" *ngIf="form('agreement').errors?.required">
                                Persetujuan harus diisi
                            </p>
                        </ng-container>

                    </div>
                </div>
                <button type="button" class="btn btn-primary" style="width: 100%">Register</button>
            </form>
        </div>
    </div>
</div>