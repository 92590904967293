import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxAuthRoutingModule } from './auth-routing.module';
import { NbAuthModule } from '@nebular/auth';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
} from '@nebular/theme';
import { LoginComponent, TrackCapsDirective } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { RegisterComponent } from './register/register.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbIconModule,
    NgxAuthRoutingModule,
    NbAuthModule,
    ReactiveFormsModule,
    NbIconModule,
    NbFormFieldModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    NbCardModule,
    TooltipModule,
    NbPopoverModule,
  ],
  declarations: [
    LoginComponent, LogoutComponent, ResetPasswordComponent, ForgetPasswordComponent, TrackCapsDirective, RegisterComponent
  ],
})
export class NgxAuthModule {
}