
<body>
  <div *ngIf="isLoading" class="sk-fading-circle">
    <div class="sk-circle1 sk-circle"></div>
    <div class="sk-circle2 sk-circle"></div>
    <div class="sk-circle3 sk-circle"></div>
    <div class="sk-circle4 sk-circle"></div>
    <div class="sk-circle5 sk-circle"></div>
    <div class="sk-circle6 sk-circle"></div>
    <div class="sk-circle7 sk-circle"></div>
    <div class="sk-circle8 sk-circle"></div>
    <div class="sk-circle9 sk-circle"></div>
    <div class="sk-circle10 sk-circle"></div>
    <div class="sk-circle11 sk-circle"></div>
    <div class="sk-circle12 sk-circle"></div>
    <br>
    <br><br>
    <div class="text-loading">
        <p>Loading</p>
    </div>
</div>
	
	<div *ngIf="!isLoading" class="limiter">
		<div class="container-login100" style="background-image: url('../../../assets/images/bg-01.jpg');">
			<div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
				<form class="login100-form validate-form" [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)">
					<span class="login100-form-title p-b-49">
						Login GAMA
					</span>

					<div class="wrap-input100 validate-input m-b-23" data-validate = "Username is reauired">
						<span class="label-input100">Username</span>
						<input class="input100" type="text" name="username" placeholder="Type your username" id="form3Example3" formControlName="username">
						<span class="focus-input100"></span>
            <small *ngIf="form('username').invalid && (form('username').dirty || (form('username').touched))" class="text text-danger float-left">
              <span *ngIf="form('username').errors?.required">
                  Username harus di isi
              </span>
            </small>
					</div>

					<div class="wrap-input100 validate-input" data-validate="Password is required">
						<span class="label-input100">Password</span>
						<input class="input100" type="password" name="pass" placeholder="Type your password" (capsLock)="capsOn=$event" [type]="getInputType()" nbPopover="Username is already taken" nbPopoverTrigger="noop" (keyup)="inputPassword($event.target.value)" nbInput id="exampleInputPassword1"
            formControlName="password">
            <span class="focus-input100"></span>
            <div class="input-group-append">
              <span *ngIf="isCapslock" class="input-group-text" id="basic-addon2"><i class="fa fa-bell"></i></span>
              <span class="input-group-text" id="basic-addon2" (click)="toggleShowPassword()"><i class="fa fa-eye" *ngIf="!showPassword"></i><i class="fa fa-eye-slash" *ngIf="showPassword"></i></span>
            </div>
            <div class="notif">
              <small *ngIf="capsOn" class="text text-danger float-left">Capslock is on</small>
              <small *ngIf="form('password').invalid && (form('password').dirty || (form('password').touched))" class="text text-danger float-left">
                  <span *ngIf="form('password').errors?.required">
                      Kata sandi harus di isi
                  </span>
              </small>
            </div>
					</div>
					
					<div class="text-right p-t-8 p-b-31">
						<a href="">
							
						</a>
					</div>
					
					<div class="container-login100-form-btn">
						<div class="wrap-login100-form-btn">
							<div class="login100-form-bgbtn"></div>
							<button class="login100-form-btn" type ="submit">
								Login
							</button>
						</div>
					</div>

					<!-- <div class="txt1 text-center p-t-54 p-b-20">
						<span>
							Or Sign Up Using
						</span>
					</div>

					<div class="flex-c-m">
						<a href="#" class="login100-social-item bg1">
							<i class="fa fa-facebook"></i>
						</a>

						<a href="#" class="login100-social-item bg2">
							<i class="fa fa-twitter"></i>
						</a>

						<a href="#" class="login100-social-item bg3">
							<i class="fa fa-google"></i>
						</a>
					</div>

					<div class="flex-col-c p-t-155">
						<span class="txt1 p-b-17">
							Or Sign Up Using
						</span>

						<a href="#" class="txt2">
							Sign Up
						</a>
					</div> -->
				</form>
			</div>
		</div>
	</div>
	

	<div id="dropDownSelect1"></div>
</body>