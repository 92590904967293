import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import {
  AuthData,
  requestLogin,
  responseLogin,
  responseTransaction,
} from "../../data/data-wco/auth";
import { HelperService } from "../../utils/helper.service";

@Injectable({
  providedIn: "root",
})
export class AuthService extends AuthData {
  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService
  ) {
    super();
  }

  loginData(fieldLogin: requestLogin): Observable<responseLogin> {
    const formdata = new HttpParams()
      .set("username", fieldLogin.username)
      .set("password", fieldLogin.password)
      .set("grant_type", "password");

    const loginValue =
      "Gama-safe-home" + ":" + "x2fzh4c8-8ej3-52bf-85g3-554bfr65t871";
    const headers = {
      Authorization: "Basic " + btoa(loginValue),
      "Content-type": "application/x-www-form-urlencoded",
    };

    return new Observable((observer: Observer<responseLogin>) => {
      this.httpClient.post("/api/oauth/token", formdata, { headers }).subscribe(
        (response: any) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error.message);
        }
      );
    });
  }

  validasiTokenData(accessToken: string): Observable<boolean> {
    let headersDict = {
      Authorization: "Bearer " + accessToken,
    };
    let requestOptions = {
      headers: new HttpHeaders(headersDict),
    };

    console.log("headersDictnya adalah: ", requestOptions);

    return new Observable((observer: Observer<boolean>) => {
      this.httpClient
        .get("/api/ask/asum/safeHome/validateToken", requestOptions)
        .subscribe(
          (response: boolean) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  getRequestByTransactionId(
    transactionID: String
  ): Observable<responseTransaction> {
    return new Observable((observer: Observer<responseTransaction>) => {
      this.httpClient
        .get("/api/ask/asum/safeHome/request/" + transactionID)
        .subscribe(
          (response: responseTransaction) => {
            observer.next(response);
          },
          (error) => {
            console.log(" errornya adalah: ");
            observer.error(error);
          }
        );
    });
  }
}
