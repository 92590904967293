import {
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { AuthLoginService } from "../../AuthLoginService";
import { ReCaptcha2Component } from "ngx-captcha";
import { LoginService } from "../../@core/mock/mock-wco/login.service";
import { HelperService } from "../../@core/utils/helper.service";
import { LoginResponse } from "../../@core/data/data-wco/login";
import { SecurityService } from "../../security/security.service";
import { LoginRequest } from "../../@core/data/data-wco/login";

@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @ViewChild("captchaElem", { static: false }) captchaElem: ReCaptcha2Component;
  @ViewChild("langInput", { static: false }) langInput: ElementRef;

  isCapslock = false;
  showPassword = false;
  loginForm: FormGroup;
  fieldLogin: LoginRequest;
  token: any;
  x: any;
  valueEmail!: string;
  forgetPassword: any;
  isLoading: boolean = false;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public lang = "en";
  public type: "image" | "audio";
  public useGlobalDomain: boolean = false;
  public readonly siteKey = "6Le-khMdAAAAAO863s4KiI9W34zqd_dEGt21vtkO";
  capsOn;
  public counter: number;
  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private authLoginService: AuthLoginService,
    private securityService: SecurityService,
    private helperService: HelperService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.formValidation();
    // if (this.router.url === "/") {
    //   this.router.navigate(["/home"]);
    // } else {
    //   if (localStorage.getItem("token") != null) {
    //     this.router.navigate(["/pages"]);
    //   }
    // }
  }

  form(property: any): AbstractControl {
    return this.loginForm.get(property);
  }

  inputPassword(strings: any) {
    console.log(" valuenya: ", strings);
    var a = strings;
    var character: any;
    var i = 0;

    while (i <= strings.length) {
      character = strings.charAt(i);
      if (!isNaN(character * 1)) {
      } else {
        if (character == character.toUpperCase()) {
          this.isCapslock = true;
        }
        if (character == character.toLowerCase()) {
          this.isCapslock = false;
        }
      }
      i++;
    }
  }

  formValidation(): void {
    this.loginForm = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      recaptcha: new FormControl("", [Validators.required]),
    });
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  login(value: LoginRequest) {
    this.isLoading = true;
    this.loginService
      .postLoginService({
        username: value.username,
        password: value.password,
      })
      .subscribe(
        (response: LoginResponse) => {
          this.securityService.login([
            {
              key: "token",
              value: response.access_token,
            },
            {
              key: "name",
              value: response.user.name,
            },
            {
              key: "idCabang",
              value: response.user.idCabang,
            },
            {
              key: "role",
              value: response.user.role,
            },
            {
              key: "username",
              value: response.user.username,
            },
            {
              key: "isActive",
              value: response.user.isActive,
            },
          ]);
        },
        (error) => {
          if (error.status === 500) {
            this.showWarning("Username atau Password salah");
          } else {
            this.showWarning(error.error.error_description);
          }
        }
      );
  }

  toForgot() {
    console.log(" forgot password ");
    this.router.navigate([`/auth/forget-password/`]);
  }

  toRegister() {
    this.router.navigate([`/auth/register/`]);
  }

  setLanguage(): void {
    this.lang = this.langInput.nativeElement.value;
  }

  setUseGlobalDomain(use: boolean): void {
    this.useGlobalDomain = use;
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    // if (!currentResponse) {
    //   alert('There is no current response - have you submitted captcha?');
    // } else {
    //   alert(currentResponse);
    // }
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    // if (!response) {
    //   alert('There is no response - have you submitted captcha?');
    // } else {
    //   alert(response);
    // }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    // alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  getInputType() {
    if (this.showPassword) {
      return "text";
    }
    return "password";
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  showWarning(message: any) {
    Swal.fire({
      title: "Failed",
      text: message,
      icon: "warning",
      timer: 5000,
      timerProgressBar: true,
    });
    this.isLoading = false;
    this.loginForm.get("recaptcha").setValue("");
  }
}

@Directive({ selector: "[capsLock]" })
export class TrackCapsDirective {
  @Output("capsLock") capsLock = new EventEmitter<Boolean>();

  @HostListener("window:keydown", ["$event"])
  onKeyDown(event: KeyboardEvent): void {
    this.capsLock.emit(
      event.getModifierState && event.getModifierState("CapsLock")
    );
    if (event.getModifierState && event.getModifierState("CapsLock")) {
      console.log("dataku");
    }
  }
  @HostListener("window:keyup", ["$event"])
  onKeyUp(event: KeyboardEvent): void {
    this.capsLock.emit(
      event.getModifierState && event.getModifierState("CapsLock")
    );
    if (event.getModifierState && event.getModifierState("CapsLock")) {
      console.log("dataku2");
    }
  }
}
