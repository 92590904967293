import { Observable } from "rxjs";

export interface LoginRequest {
  username: string;
  password: string;
}

export interface ChangePasswordRequest {
  email: String;
  passwordOld: String;
  passwordNew: String;
}

export interface LoginResponse {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: string;
  scope: string;
  data: {
    clientId: string;
    transactionDateRequest: string;
    transactionDateResponse: string;
    transactionIdRequest: string;
    role: string;
  };
  user: {
    username: string;
    idCabang: string;
    name: string;
    role: string;
    isActive: boolean;
  };
  jti: string;
}

export interface logoutResponse {
  username: string;
  message: string;
}

export interface ForgetPasswordRequest {
  username: string;
}

export interface ForgetPasswordResponse {
  status: boolean;
  data: [
    [
      {
        id_user: String;
        id_group: number;
        id_cabang: String;
        id_bp: String;
        id_tipe_dokumen: number;
        id_status: number;
        account_expired: boolean;
        account_locked: boolean;
        credential_is_expired: boolean;
        confirmation_token: String;
        login_token: String;
        username: String;
        password: String;
        fullname: String;
        email: String;
        is_top_enable: boolean;
        is_need_approval: boolean;
        login_attempt: number;
        description: String;
        is_active: boolean;
        version: number;
        created_by: String;
        created_date: Date;
        modified_by: String;
        modified_date: Date;
        is_confirm_unlock: boolean;
      }
    ]
  ];
}

export abstract class LoginData {
  abstract postLoginService(formLogin: LoginRequest): Observable<LoginResponse>;
  abstract getLoginData(login: LoginRequest): Observable<LoginResponse>;
  abstract loginToServer(login: LoginRequest);
  abstract changePassword(updatePassword: any, idUser: any);
  abstract changePasswordUpdate(
    changePasswordRequest: ChangePasswordRequest
  ): Observable<any>;
  abstract logoutFromServer(token: String): Observable<logoutResponse>;
}
