<div *ngIf="isLoading" class="sk-fading-circle">
    <div class="sk-circle1 sk-circle"></div>
    <div class="sk-circle2 sk-circle"></div>
    <div class="sk-circle3 sk-circle"></div>
    <div class="sk-circle4 sk-circle"></div>
    <div class="sk-circle5 sk-circle"></div>
    <div class="sk-circle6 sk-circle"></div>
    <div class="sk-circle7 sk-circle"></div>
    <div class="sk-circle8 sk-circle"></div>
    <div class="sk-circle9 sk-circle"></div>
    <div class="sk-circle10 sk-circle"></div>
    <div class="sk-circle11 sk-circle"></div>
    <div class="sk-circle12 sk-circle"></div>
    <div class="text-loading">
        <p>Loading</p>
    </div>
</div>
<div *ngIf="!isLoading" class="bg global-container">
    <div class="card login-form">
        <div class="card-body">
            <div class="card-head">
                <h3 class="card-title text-center">Gama</h3>
            </div>
            <div class="card-text">
                <form [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input type="email" class="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="username">
                        <small *ngIf="form('username').invalid && (form('username').dirty || (form('username').touched))" class="text text-danger float-left">
                            <span *ngIf="form('username').errors?.required">
                                Email harus di isi
                            </span>
                            <span *ngIf="form('username').errors?.pattern">
                                Harus menggunakan format email
                            </span>
                        </small>
                    </div>
                    <button [disabled]="loginForm.invalid" class="btn btn-primary btn-block">Next</button>
                </form>
            </div>
        </div>
    </div>
</div>