import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthLoginService } from "./AuthLoginService";
import Swal from "sweetalert2";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "./@core/mock/mock-wco/auth.service";

@Injectable()
export class AuthGuardService
  implements CanActivate, CanDeactivate<unknown>, CanLoad
{
  constructor(
    private authLoginService: AuthLoginService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const url: string = state.url;
    if (this.tokenCheck()) {
      if (window.location.pathname === "/pages/profiletest") {
        if (this.router.url === "/") {
          if (localStorage.getItem("token") === null) {
            this.router.navigate(["/auth"]); // Navigate away to some other page
          } else {
            this.router.navigate(["/auth"]); // Navigate away to some other page
          }
          return false;
        }
      }
      return this.checkUserLogin(next, url);
    } else {
      this.router.navigate(["/auth"]); // Navigate away to some other page
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    console.log("g");
    if (this.authLoginService.isLoggedIn()) {
      console.log("h");
      return true;
    }
    console.log("i");
    localStorage.clear();
    this.router.navigate(["/"]);
    return false;
  }

  tokenCheck() {
    let jwtHelper: JwtHelperService = new JwtHelperService();
    let availableToken = !!localStorage.getItem("token");
    console.log(availableToken);
    if (availableToken) {
      let token = localStorage.getItem("token") || "";
      if (jwtHelper.isTokenExpired(JSON.parse(token))) {
        Swal.fire({
          title: "Token Expired",
          text: "Silahkan Login Kembali",
          icon: "info",
        });
      }

      return !jwtHelper.isTokenExpired(JSON.parse(token));
    } else {
      return !!localStorage.getItem("token");
    }
  }
}
