<div *ngIf="isLoading" class="sk-fading-circle">
    <div class="sk-circle1 sk-circle"></div>
    <div class="sk-circle2 sk-circle"></div>
    <div class="sk-circle3 sk-circle"></div>
    <div class="sk-circle4 sk-circle"></div>
    <div class="sk-circle5 sk-circle"></div>
    <div class="sk-circle6 sk-circle"></div>
    <div class="sk-circle7 sk-circle"></div>
    <div class="sk-circle8 sk-circle"></div>
    <div class="sk-circle9 sk-circle"></div>
    <div class="sk-circle10 sk-circle"></div>
    <div class="sk-circle11 sk-circle"></div>
    <div class="sk-circle12 sk-circle"></div>
    <div class="text-loading">
        <p>Loading</p>
    </div>
</div>

<div *ngIf="!isLoading" class="bg global-container">
    <div class="card login-form">
        <div class="card-body">
            <div class="card-head">
                <img src="../../../assets/images/logo-Gama-ifg.png">
                <h3 class="card-title text-center">GAMA</h3>
            </div>
            <div class="card-text">
                <form [formGroup]="loginForm" (ngSubmit)="resetPassword(loginForm.value)">
                    <div class="form-group">
                        <label for="username">Username</label>
                        <input type="text" disabled class="form-control form-control-sm" id="username" aria-describedby="username" formControlName="username">
                    </div>
                    <div class="form-group" style="margin-top: 2px !important;">
                        <label for="password">Password</label>
                        <input [(ngModel)]="passwordFirst" type="password" class="form-control form-control-sm" id="password" formControlName="password">
                        <small *ngIf="form('password').invalid && (form('password').dirty || (form('password').touched))" class="text text-danger float-left">
                            <div *ngIf="form('password').errors?.required">
                                Kata sandi harus di isi
                            </div>
                            <div *ngIf="form('password').errors?.minlength">
                                Kata sandi minimal 8 karakter
                            </div>
                            <div *ngIf="form('password').errors?.pattern">
                                Kata sandi wajib berisi angka dan huruf
                            </div>
                        </small>
                    </div>
                    <div class="form-group" style="margin-top: 2px !important;">
                        <label for="konfirmasiPassword">Konfirmasi Password</label>
                        <input [(ngModel)]="passwordSecond" (keyup)="passwordMatch()" type="password" class="form-control form-control-sm" id="konfirmasiPassword" formControlName="konfirmasiPassword">
                        <small style="width: 100%;" *ngIf="form('konfirmasiPassword').invalid && (form('konfirmasiPassword').dirty || (form('konfirmasiPassword').touched))" class="text text-danger float-left">
                            <div *ngIf="form('konfirmasiPassword').errors?.required">
                                Konfirmasi Password harus di isi
                            </div>
                        </small>
                        <small *ngIf="isErrorValidation">
                            <div class="form-text text-danger">
                              *Password konfirmasi tidak sesuai
                            </div>
                          </small>
                    </div>
                    <button [disabled]="loginForm.invalid" class="btn btn-primary btn-block">Sign in</button>
                </form>
            </div>
        </div>
    </div>
</div>